<template>
  <v-form ref="form" class="timesheet-form">
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Reject timesheet</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="vm.rejectReason"
                  label="Reason"
                  filled
                  rows="5"
                  v-validate="'required'"
                  data-vv-as="rejectReason"
                  name="rejectReason"
                  :error-messages="errors.collect('rejectReason')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close">Cancel</v-btn>
          <v-btn class="primary" @click="reject">Reject</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col class="mt-0 pb-0" cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-btn fab small @click="previousWeek" v-if="this.mode!='Review'">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-btn fab small @click="nextWeek" v-if="this.mode!='Review'">
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
              <div v-if="this.mode==='Review'">Timesheet for {{vm.user}}</div>
              <v-spacer></v-spacer>
              Week ending: {{weekEndDate.format("dddd DD MMMM YYYY")}}
              <v-spacer></v-spacer>

              <v-chip class="ma-2">{{vm.status}}</v-chip>
              <v-btn v-if="vm.status=='Submitted' && this.mode=='Review'" @click="approve">Approve</v-btn>
              <v-btn
                v-if="vm.status=='Submitted' && this.mode=='Review'"
                @click="dialog=true"
              >Reject</v-btn>
              <v-btn
                v-if="vm.status==='Draft' || vm.status==='Rejected'"
                @click="saveDraft"
              >Save draft</v-btn>
              <v-btn
                color="primary"
                v-if="vm.status==='Draft' || vm.status==='Rejected'"
                @click="submit"
              >Submit for approval</v-btn>
            </v-row>
            <v-row class="mt-4" v-if="vm.status==='Draft' || vm.status==='Rejected'">
              <v-btn @click="getEvents" small>Load from roster</v-btn>&nbsp;
              <v-btn @click="loadProgressNotes" small>Load from progress notes</v-btn>&nbsp;
              <v-btn @click="clear" small>Clear</v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card> 
          <div class="px-5 py-4 " v-if="vm.status=='Rejected'">
              <v-icon size="32" color="orange darken-2">mdi-alert</v-icon>
            <span class="orange--text text--darken-2 font-weight-medium">{{vm.status}}:</span> {{vm.rejectReason}}

          </div>
         <v-divider></v-divider>
          <v-data-table
            id="list-table"
             :items-per-page-options= "[ 10, 20, 30, 40, 50]"
        :items-per-page="50"
            ref="datatable"
            :headers="[  { text: 'Date', value: 'date' },
                { text: 'Task', value: 'task' }, 
                { text: 'Start', value: 'start' }, 
                { text: 'End', value: 'end' }, 
                
                { text: 'Notes', value: 'notes' },
                { text: 'Duration', value: 'duration' },
                 { text: '', value: '' }]"
            :items="items"
            item-key="index"
            class="mr-2"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>
                  <date-picker
                    :disabled="vm.status!=='Draft' && vm.status!=='Rejected'"
                    v-model="props.item.date"
                    v-validate="'required'"
                    data-vv-as="dateString"
                    prepend-icon="mdi-calendar"
                    :max="weekEndDate.format('YYYY-MM-DD')"
                    :min="sunday.format('YYYY-MM-DD')"
                    name="dateString"
                    :solo="true"
                  ></date-picker>
                </td>
                <td>
                  <v-text-field
                    v-if="!(vm.status!=='Draft' && vm.status!=='Rejected')"
                    v-model="props.item.task"
                    v-validate="'required'"
                    data-vv-as="task"
                    name="task"
                    solo
                    :error-messages="errors.collect('task')"
                  ></v-text-field>
                  <div v-if="vm.status!=='Draft' && vm.status!=='Rejected'">{{props.item.task}}</div>
                </td>
                <td>
                  <time-picker
                    :disabled="vm.status!=='Draft' && vm.status!=='Rejected'"
                    v-model="props.item.start"
                    v-validate="'required'"
                    data-vv-as="startTimeString"
                    name="startTimeString"
                  ></time-picker>
                </td>
                <td>
                  <time-picker
                    :disabled="vm.status!=='Draft' && vm.status!=='Rejected'"
                    v-model="props.item.end"
                    v-validate="'required'"
                    data-vv-as="endTimeString"
                    name="endTimeString"
                  ></time-picker>
                </td>

                <td>
                  <v-text-field
                    v-if="!(vm.status!=='Draft' && vm.status!=='Rejected')"
                    v-model="props.item.notes"
                    data-vv-as="notes"
                    name="notes"
                    solo
                    :error-messages="errors.collect('notes')"
                  ></v-text-field>
                  <div v-if="vm.status!=='Draft' && vm.status!=='Rejected'">{{props.item.notes}}</div>
                </td>
                <td>{{calculateHours(props.item)}}</td>
                <td
                  class="justify-center layout px-0 mt-3"
                  v-if="vm.status==='Draft' || vm.status==='Rejected'"
                >
                  <v-btn fab small @click="removeRow(props.item)" v-if="items.length>1 ">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>&nbsp;
                  <v-btn class="secondary" fab small @click="addRow()">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as timesheetService from "../../services/timesheetService";
import * as notesService from "../../services/progressNotesService";
import * as eventService from "../../services/eventService";
import TimePicker from "../../components/core/TimePicker";
import DatePicker from "../../components/core/DatePicker";

Vue.use(VeeValidate);
import moment from "moment";
export default {
  name: "notes",
  components: {
    TimePicker,
    DatePicker
  },
  data: () => ({
    editedIndex: -1,
    dialog: false,
    weekEndDate: moment(new Date()),
    vm: { items: [] },
    items: [],
    events: [],
    progressNotes: [],
    mode: "Edit",
    sunday: moment(new Date()),
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ]
  }),

  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentTimesheet() {
      return this.$store.getters.currentTimesheet;
    },
    user() {
      return this.$store.getters.user;
    },
    weekEndDateStored() {
    return this.$store.getters.weekEndDate;
  },
  },
  methods: {
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },

    init() {
      this.sunday = moment(this.getSunday(new Date()));

      let weekEnd = moment(this.sunday.toDate())
        .add(6, "days")
        .toDate();
        if(this.weekEndDateStored!==undefined && this.weekEndDateStored._isAMomentObject!==undefined){
          const sunday = moment(this.getSunday(this.weekEndDateStored.toDate()));
          weekEnd=moment(sunday.toDate()).add(6, "days").toDate();
        }
      weekEnd.setHours(23);
      weekEnd.setMinutes(59);
      weekEnd.setSeconds(59);
      
      this.weekEndDate = moment(weekEnd);

      if (this.$route.meta.breadcrumb[1].link == "/timesheets/review") {
        this.mode = "Review";
      }
      if (
        this.mode == "Review" &&
        this.currentTimesheet != null &&
        this.currentTimesheet != undefined
      ) {
        this.vm = this.currentTimesheet;
        this.items = this.vm.items;
      } else {
        this.vm = {
          items: [],
          status: "Draft"
        };
        
           this.getTimesheet();
      }
    },
    approve() {
      const me = this;
      me.vm.status = "Approved";
      me.vm.ApprovedBy = this.user;
      me.vm.ApprovedOn = new Date();
      timesheetService
        .save(me.currentOrganisation.id, me.vm)
        .then(ref => {
          if (ref != undefined) {
            me.vm.id = ref.id;
          }
          me.$store.dispatch("setToast", {
            message: "Timesheet saved",
            color: "success"
          });
          this.$router.push("/timesheets/review");
          // this.$router.push("/dashboard");
        })
        .catch(error => {
          console.error("Error saving timesheet: ", error);
        });
    },
    close() {
      this.dialog = false;
    },
    reject() {
      const me = this;
      me.vm.status = "Rejected";
      me.vm.RejectedBy = this.user;
      me.vm.RejectedOn = new Date();
      this.dialog = false;
      timesheetService
        .save(me.currentOrganisation.id, me.vm)
        .then(ref => {
          if (ref != undefined) {
            me.vm.id = ref.id;
          }
          me.$store.dispatch("setToast", {
            message: "Timesheet saved",
            color: "success"
          });
          this.$router.push("/timesheets/review");
          // this.$router.push("/dashboard");
        })
        .catch(error => {
          console.error("Error saving timesheet: ", error);
        });
    },
    getTimesheet() {
      const me = this;

      timesheetService
        .listByUserIdWeekEnding(
          this.currentOrganisation.id,
          this.user.id,
          this.weekEndDate.format("YYYY-MM-DD")
        )
        .then(result => {
          if (result.size > 0) {
            me.items.splice(0, me.items.length);
            result.docs.map(doc => {
              me.vm = doc.data();
              me.vm.id = doc.id;
              me.vm.items.forEach(x => {
                me.items.push(x);
              });
            });
          } else {
            me.addRow();
            me.getEvents();
            me.loadProgressNotes();
          }
        });
    },
    loadProgressNotes() {
      const me = this;
      const weekStart = moment(this.weekEndDate.format("YYYY-MM-DD")).add(
        -6,
        "days"
      );
      notesService
        .listAllForOrganisation(
          this.currentOrganisation.id,
          weekStart.format("YYYY-MM-DD"),
          this.weekEndDate.format("YYYY-MM-DD"),
          true, //nDISManaged
          true, //internalyManaged,
          this.user
        )
        .then(results => {
          results.docs.map(doc => {
            let item = doc.data();

            item.id = doc.id;
            item.start = item.startDate.toDate();
            item.end = item.endDate.toDate();
            
            let currentDate = moment(item.start);
            let end = moment(item.end);
            let diffDays = end.diff(currentDate, "days");
            if (diffDays >= 1) {
              for (let i = 0; i <= diffDays; i++) {
                let day = moment(me.start).add(i, "days");
                me.items.push({
                  date: day.format("YYYY-MM-DD"),
                  task:
                    item.participantName +
                    ": (" +
                    item.lineItem.supportItemNumber +
                    ")" +
                    item.lineItem.supportItem,
                  start: i == 0 ? moment(item.start).format("HH:mm") : "00:01",
                  end:
                    i == diffDays ? moment(item.end).format("HH:mm") : "24:00",
                    notes: item.notes
                });
              }
            } else {
              me.items.push({
                date: moment(item.start).format("YYYY-MM-DD"),
                task:
                  item.participantName +
                  ": (" +
                  item.lineItem.supportItemNumber +
                  ")" +
                  item.lineItem.supportItem,
                start: moment(item.start).format("HH:mm"),
                end: moment(item.end).format("HH:mm"),
                notes:item.notes
              });
            }
          });
        });
    },
    nextWeek() {
      const weekEnd = this.weekEndDate.add(7, "days");
      this.vm = { items: [], weekEndDate: weekEnd, status: "Draft" };
      this.sunday = moment(weekEnd.toDate()).add(-6, "days");
      this.items.splice(0, this.items.length);
      // this.items.push({ date: this.sunday.format("YYYY-MM-DD") });
      this.getTimesheet();
      this.$forceUpdate();
    },
    previousWeek() {
      const weekEnd = this.weekEndDate.add(-7, "days");
      this.vm = { items: [], weekEndDate: weekEnd, status: "Draft" };
      this.sunday = moment(weekEnd.toDate()).add(-6, "days");
      this.items.splice(0, this.items.length);
      //  this.items.push({ date: this.sunday.format("YYYY-MM-DD") });
      this.getTimesheet();
      this.$forceUpdate();
    },
    calculateHours(item) {
      if (item.start != undefined && item.end != undefined) {
        const dt = moment(new Date());
        const dtStart = moment(dt.format("YYYY-MM-DD") + " " + item.start);
        const dtEnd = moment(dt.format("YYYY-MM-DD") + " " + item.end);
        const diff = dtEnd.diff(dtStart, "minutes");
        const hours = Math.floor(diff / 60);
        const minutes = diff % 60;

        return `${hours} hours, ${minutes} minutes`;
      } else {
        return `0 hours, 0 minutes`;
      }
    },
    addRow() {
      const newDay = moment(this.getSunday(new Date()))
        .add(this.items.length, "days")
        .format("YYYY-MM-DD");

      this.items.push({ date: newDay });
    },
    removeRow(item) {
      const index = this.items.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        this.items.splice(index, 1);
      }
    },
    getSunday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day;
      var newDt = new Date(d.setDate(diff));
      newDt.setHours(0);
      newDt.setMinutes(0);
      newDt.setSeconds(0);
      return newDt;
    },

    editItem(item) {
      this.editedIndex = this.notes.indexOf(item);
      this.vm = Object.assign({}, item);
      // this.dialog = true;
    },
    saveDraft() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.vm.status = "Draft";
          this.save();
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    submit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.vm.status = "Submitted";
          this.save();
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    save() {
      let me = this;
      me.vm.items = me.items;
      me.vm.weekEndDate = me.weekEndDate.format("YYYY-MM-DD");
      me.vm.userId = me.user.id;
      me.vm.user = me.user.firstName + " " + me.user.lastName;

      timesheetService
        .save(me.currentOrganisation.id, me.vm)
        .then(ref => {
          if (ref != undefined) {
            me.vm.id = ref.id;
          }
          me.$store.dispatch("setToast", {
            message: "Timesheet saved",
            color: "success"
          });
          // this.$router.push("/dashboard");
        })
        .catch(error => {
          console.error("Error saving timesheet: ", error);
        });
    },
    clear() {
      let me = this;
      me.items.splice(0, me.items.length);
    },
    getEvents() {
      const me = this;

      eventService
        .getByDatesAndUserId(
          this.currentOrganisation.id,

          this.sunday.toDate(),
          this.weekEndDate.toDate(),
          this.user.id
        )
        .then(resultx => {
          resultx.subscribe(result => {
            me.events.splice(0, me.events.length);
            result.map(data => {
              if (!data.isReccuring) {
                data.start = data.start.toDate();
                data.end = data.end.toDate();
              } else {
                if (data.startRecurDate != undefined) {
                  data.startRecur = data.startRecurDate.toDate();
                }
                if (data.endRecurDate != undefined) {
                  data.endRecur = data.endRecurDate.toDate();
                }
              }
              if (data.isReccuring) {
                data.daysOfWeek.forEach(dow => {
                  let day = moment(me.sunday.toDate()).add(dow, "days");
                  me.items.push({
                    date: day.format("YYYY-MM-DD"),
                    task: data.title,
                    start: data.startTimeString,
                    end: data.endTimeString
                  });
                });
              } else if (data.isMultiDayEvent) {
                let currentDate = moment(data.start);
                let end = moment(data.end);
                let diffDays = end.diff(currentDate, "days");

                for (let i = 0; i <= diffDays; i++) {
                  let day = moment(me.start).add(i, "days");
                  me.items.push({
                    date: day.format("YYYY-MM-DD"),
                    task: data.title,
                    start: i == 0 ? data.startTimeString : "00:01",
                    end: i == diffDays ? data.endTimeString : "24:00"
                  });
                }
              } else {
                me.items.push({
                  date: moment(data.start).format("YYYY-MM-DD"),
                  task: data.title,
                  start: data.startTimeString,
                  end: data.endTimeString
                });
              }

              me.events.push(data);
            });

            me.loading = false;
            return me.events;
          });
          // if (me.events.length == 0) {
          //   this.items.push({ date: this.sunday.format("YYYY-MM-DD") });
          // }
        })
        .catch(error => {
          me.loading = false;
          console.log(error);
        })
        .finally(() => {
          me.loading = false;
        });
    }
  }
};
</script>
<style>
.timesheet-form .v-data-table td {
  font-size: 13px;
}

.timesheet-form .theme--light.v-input input,
.timesheet-form .theme--light.v-input textarea {
  font-size: 13px;
}
</style>